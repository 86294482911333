<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div class="container m-t-100">
    <div class="icon text-center">
        <span class="fa fa-lock fa-4x"></span>
    </div>
    <div class="sec-text text-center mt-2 mb-2">
        <h5>Change Password</h5>
    </div>
    <div class="form-wrap">
        <form [formGroup]="changePassForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" formControlName="email" readonly>
                <div *ngIf="submitted && f.email.errors">
                    <small class="form-text text-danger" *ngIf="f.email.errors.required">
                        Email id is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.email.errors.email">
                        Valid Email id is required.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label for="newpass">Old Password</label>
                <div class="input-group">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="oldPassword" autocomplete="off" list="autocompleteOff">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="pi" [ngClass]="{
                              'pi-eye-slash': !fieldTextType,
                              'pi-eye': fieldTextType
                            }" (click)="toggleFieldTextType()"></i>
                        </span>
                    </div>
                </div>
                
                <div *ngIf="submitted && f.oldPassword.errors">
                    <small class="form-text text-danger" *ngIf="f.oldPassword.errors.required">
                        Old Password is required.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label for="cpass">New Password</label>
                <div class="input-group">
                    <input [type]="fieldTextType1 ? 'text' : 'password'" class="form-control" formControlName="newPassword" autocomplete="off" list="autocompleteOff">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="pi" [ngClass]="{
                              'pi-eye-slash': !fieldTextType1,
                              'pi-eye': fieldTextType1
                            }" (click)="toggleFieldTextType1()"></i>
                        </span>
                    </div>
                </div>
                
                <small class="form-text hint-text">
                    Password should be at least 8 characters long with combination of one captial letter,one digit and one symbol eg.A90$asdb
                </small>
                <div *ngIf="submitted && f.newPassword.errors">
                    <small class="form-text text-danger" *ngIf="f.newPassword.errors.required">
                        New Password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newPassword.errors.minlength ">
                        New Password should be at least 8 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newPassword.errors.pattern ">
                        Password should be combination of at least one uppercase, one lower case, one digit and one special character.
                    </small>
                </div>
            </div>
            <button class="btn btn-primary mr-2" [disabled]="btnDisabled" type="submit">Change Password</button>
            <button class="btn btn-secondary" [disabled]="btnDisabled" (click)="goBack()">Cancel</button>

        </form>
    </div>
</div>